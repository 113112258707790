import React from 'react';

export default class NotMatch extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div>404 页面丢了...</div>
    );
  }
}
